<template>
  <div>
    <div class="loginPage">
      <div class="loginWhiteBox">
        <div class="loginLogoBox">
          <img src="@/assets/images/yeet/yeet_logo.png" alt="">
        </div>
        <div class="loginTitlebox">
          <h4>Forgot Password</h4>
          <p>A password reset link will be sent to the submitted email</p>
        </div>
        <div class="loginFormBox">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(forgotPassword)">
              <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                <div class="formType">
                  <label for="email">Email<span>*</span></label>
                  <input type="email" name="email" id="email" v-model="form.email" class="form-control" placeholder="john@gmail.com"  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                  <div class="forgotPasswordBtn">
                    <router-link to="/login">Return to Login</router-link>
                  </div>
                </div>
              </div> 
              <div class="formSubmitBtn">
                <loaderBtn v-if="isLoading"/>
                <button type="submit" v-else>SUBMIT</button> 
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import loaderBtn from "@/components/loaderBtn.vue";

export default {
  name:'ForgotPassword',
  metaInfo(){
    return {
      title: 'Forgot Password | YeetCommerce', 
      meta: [
        {
          name: 'description',
          content: "Initiate the process of resetting your password by adding your email address. A reset link will be sent to your email address by which you can reset your password with ease.",
        },
        {
          name: 'canonical',
          content: this.fullPath(),
        },
      ],
    };
  },
  data:()=>({
    form:{
      email:'',
      password:'',
    },
    isLoading:false,
  }),
  components:{
    loaderBtn
  },
  methods: {
    async forgotPassword(){

      this.isLoading = true;
      try{

        let formData = new FormData();

        formData.append('email',this.form.email);

        let res = await this.$axios.post('/forgotPassword', formData);
        if(res.data.status_code == "1205"){

          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

        }
      }catch(error){

        if(error.response){

          if(error.response.data.error.email){

            this.$message({
              type: 'error',
              showClose: true,
              message: "The email you entered is not associated with any store.",
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }
        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });
          
        }

      }finally{

        this.isLoading = false;

      }

    },
    fullPath(){

      return window.location.origin + this.$route.fullPath;
      
    }
  },
  async beforeMount(){

  },
}
</script>

<style scoped>
  /* Login New Page Css Start Here */

  .loginPage{
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    padding: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #1b3561, #25799d);
    /* background: linear-gradient(45deg, rgba(207, 76, 228, 1) 0%, rgba(128, 32, 225, 1) 100%); */
  }
  .loginWhiteBox{
    max-width: 652px;
    width: 100%;
    background-color: #fff;
    padding: 59px 44px 58px;
    box-shadow: 0 3px 15px 0 rgb(0 0 0 / 24%);
  }
  .loginLogoBox{
    width: 160px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  .loginLogoBox a{
    display: block;
  }
  .loginLogoBox img{
    width: 100%;
  }
  .loginTitlebox{
    text-align: center;
    margin-bottom: 33px;
  }
  .loginTitlebox h4{
    font-size: 24px;
    color: #000000;
    margin-bottom: 11px;
    font-weight: 700;
  }
  .loginTitlebox p{
    font-size: 14px;
    color: #9E9B9B;
    margin-bottom: 0px;
    font-weight: 400;
  }
  .loginFormBox .formType label{
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 10px;
  }
  .loginFormBox .formType label span{
    color: #FD4D5D;
  }
  .loginFormBox .formType input{
    border: 1px solid #e8e8e8 !important;
    background-color: #FCFCFC !important;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    border-radius: 4px;
  }
  .loginFormBox .formType .text-danger{
    font-size: 13px;
    color: #dc3545;
    font-style: italic;
  }
  .text-danger{
    font-size: 13px;
    color: #dc3545;
    font-style: italic;
  }
  .formType.checkRememberBox{
    display: flex;
    align-items: center;
  }
  .formType.checkRememberBox input{
    border-radius: 0px !important;
    width: 17px;
    height: 17px;
    margin-right: 10px;
  }
  .formType.checkRememberBox label{
    margin-bottom: 0px !important;
    font-size: 16px;
    font-weight: 400;
  }
  .forgotPasswordBtn{
    text-align: right;
  }
  .forgotPasswordBtn a{
    font-size: 16px;
    font-weight: 400;
    color: #14223d;
    display: inline-block;
  }
  .loginFormBox .row{
    margin-bottom: 41px;
  }
  .loginFormBox .formSubmitBtn{
    text-align: center;
    margin-bottom: 20px;
  }
  .loginFormBox .formSubmitBtn button{
    width: 60%;
    margin: 0 auto;
    font-weight: 700;
  }
  .formBottomTxt{
    text-align: center;
  }
  .formBottomTxt p{
    font-size: 14px;
    color: #9E9B9B;
    font-weight: 400;
    margin-bottom: 30px;
  }
  .formBottomTxt p a{
    color: #14223d;
    display: inline-block;
    text-decoration: none;
  }
  .formBottomTxt h4{
    color: #9E9B9B;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 0px;
  }
  .formBottomTxt h4 button{
    color: #14223d;
    background-color: transparent;
    padding: 0px;
    border-radius: 0px;
    border: none;
  }
  .formSubmitBtn button {
    width: 100%;
    background-color: #14223d;
    /* background: linear-gradient(45deg, rgba(207, 76, 228, 1) 0%, rgba(128, 32, 225, 1) 100%); */
    border: none !important;
    color: #fff;
    padding: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .formSubmitBtn button:hover {
    background-color: #fd4e5d !important;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  /* Login New Page Css End Here */
</style>